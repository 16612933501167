import React from "react";
import styled from "styled-components";
import LinksSection from "./homeComponents/linksSection";

const Home = () => {
  return (
    <HomeContainer>
      <LinksSection />
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  height: 85vh;
`;
