import React from "react";
import styled from "styled-components";

const LinkButton = ({ name, destination, type }) => {
  const handleLinkClick = () => {
    window.open(destination, "_blank", "noreferrer");
  };

  return (
    <ButtonContainer
      href={destination}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p className="name">{name}</p>
    </ButtonContainer>
  );
};

export default LinkButton;

const ButtonContainer = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ff5749;
  align-items: center;
  justify-content: center;
  width: 70vw;
  margin: 10px auto;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  .name {
    text-decoration: none;
    color: white;
    font-size: 17px;
  }
`;
