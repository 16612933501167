import React from "react";
import styled from "styled-components";
import LinkButton from "./linkButton";

const LinksSection = () => {
  return (
    <LinksContainer>
      <LinkButton name="Shop Online" destination="https://wishs.in" />
      <LinkButton
        name="WhatsApp"
        destination="https://wa.me/message/B6RLKF2GDQMBL1"
      />
      <LinkButton
        name="Reviews"
        destination="https://goo.gl/maps/HWdS1h4H7J8uvjda6"
      />
      <LinkButton name="Call" destination="tel:+918072197822" />
      <LinkButton name="Mail" destination="mailto:hey@wishs.in" />
    </LinksContainer>
  );
};

export default LinksSection;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
