import React from "react";
import styled from "styled-components";
import LOGO from "./../Media/LOGO.png";

const Header = () => {
  return (
    <HeaderContainer>
      <img src={LOGO} alt="" className="logo" />
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  position: sticky;
  width: 100vw;
  top: 0;
  height: 60px;
  left: 0;
  background-color: #fff0f0;
  z-index: 1000;
  align-items: center;
  justify-content: center;

  .logo {
    width: 130px;
    margin: 0 auto;
  }
`;
